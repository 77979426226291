@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

:root {
    --black: #050606;
    --black-rgb: rgb(5, 6, 6);
    --white: #e9ebdf;
    --white-rgb: rgb(233, 235, 223);
    --light-golden: #eaefa2;
    --light-golden-rgb: rgb(234, 239, 162);
    --mid-golden: #d4c06c;
    --mid-golden-rgb: rgb(212, 192, 108);
    --golden: #b6861d;
    --golden-rgb: rgb(182, 134, 29);
    --light-brown: #b4843c;
    --light-brown-rgb: rgb(180, 132, 60);
    --brown: #9c5c04;
    --brown-rgb: rgb(156, 92, 4);
    --light-blue: #7b908f;
    --light-blue-rgb: rgb(123, 144, 143);
    --mid-blue: #5c6c74;
    --mid-blue-rgb: rgb(92, 108, 116);
    --dark-blue: #5c7471;
    --dark-blue-rgb: rgb(92, 116, 113);
    --heading: 'Dancing Script', cursive;
    --gradient: linear-gradient(315deg, #fffcf9 0%, #ffd166 74%);

}

h1 {
    text-align: center;
    text-transform: capitalize;
    margin: 1% 0;
    font-family: var(--heading);
    font-size: 4vw;
    color: #fff;
}


.blog {
    padding: 5%;
    margin-top: 5%;

    .blog-body {


        .blog-body-content {
            display: flex;
            flex-wrap: wrap;

            .blog-card {
                position: relative;
                padding-top: 5%;
                border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
                width: calc(100% / 3.2) !Important;
                text-decoration: none;
                margin: 10px;

                &:hover {
                    transform: scale(1.04);
                    transition: .5s all ease-in-out;
                }

                h3 {
                    color: var(--mid-golden);
                    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
                    font-size: 20px;
                    margin: 10px 0;

                }

                img {
                    width: 100%;
                }


                .blog-card-text {
                    display: flex;
                    flex-direction: column;


                    .category {
                        display: flex;
                        align-items: baseline;
                        margin-top: 2%;
                        flex-wrap: wrap;

                        h6 {
                            margin: 0;
                            font-size: 18px;
                        }

                        p {
                            margin: 0;
                            background: var(--mid-golden);
                            margin: .5%;
                            padding: 2px 10px;
                            font-size: 12px;
                            border-radius: 5px;
                            color: #fff;
                        }
                    }

                    .date {
                        position: absolute;
                        top: 0;
                        left: 5%;
                        background: #fff;
                        padding: 1% 3%;
                        border-radius: 0 0 10px 10px;
                        font-size: 1.5vw;
                        font-weight: bold;
                    }

                    a {
                        font-size: 2vw;
                        color: var(--mid-golden);
                        margin-top: 2%;
                    }

                    .Read {
                        font-size: 1.3vw;
                        margin: 3% 0;
                    }

                    .body {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        /* number of lines to show */
                        line-clamp: 3;
                        -webkit-box-orient: vertical;
                        z-index: -1;
                        margin-top: 3%;
                    }
                }

            }
        }

        .blog-body-text {
            width: 30%;
            padding-left: 5%;


            .blog-body-categories {

                &:last-child {
                    h4 {
                        padding-top: 15%;
                    }
                }

                h4 {
                    font-size: 2vw;
                    color: rgba($color: #000, $alpha: 0.8);
                    border-bottom: 2px solid var(--mid-golden);
                    padding-bottom: 3%;
                    margin: 0;
                }

                p {
                    &:hover {
                        transition: .5s all ease;
                        padding-left: 7%;
                        color: var(--mid-golden);
                    }
                }

                .latest {
                    border-bottom: 1px solid rgba($color: #000, $alpha: 0.2);
                    padding: 5% 0;

                    &:hover {
                        transition: .5s all ease;
                        padding-left: 7%;

                        a {
                            transition: .5s all ease;
                            color: var(--mid-golden);
                        }
                    }
                }
            }
        }

    }
}

.blog-details {
    padding: 5% 10%;
    margin-top: 5%;

    img {
        width: 60%;
        display: block;
        margin: auto;
        margin-bottom: 0;
    }

    .blog-card-text {
        ul {
            padding-left: 5%;
        }

        h1 {
            font-size: 40px;
            margin: 5% 0;
            text-align: center;
            text-transform: capitalize;
            color: var(--mid-golden);
            line-height: 150%;
        }

        p {
            color: rgba($color: #fff, $alpha: 0.7);
            letter-spacing: .3px;
            line-height: 200%;
            font-size: 16px;

            strong {
                font-size: 1.5vw;
                color: #000;
                letter-spacing: .3px;
            }
        }

        ol,
        ul {
            li {
                list-style-type: unset;
                margin: 1% 0 1% 0;
                color: rgba($color: #fff, $alpha: 0.7);
                letter-spacing: .3px;
                font-size: 16px;

                strong {
                    color: #000;
                }

            }
        }

        a {
            color: var(--mid-golden);
            text-decoration: none;
            font-style: italic;
            font-size: 16px;
        }

    }

    .blog-details-text {
        padding: 0 5%;

        h1 {
            text-transform: capitalize;
            text-align: center;
            margin-bottom: 5%;
            font-size: 2.5vw;
            line-height: 150%;
            font-weight: bold;
            color: var(--mid-golden);
        }

        a {
            color: var(--mid-golden);
            text-decoration: underline;
        }

        li {
            list-style-type: unset;
        }





        strong {
            font-size: 1.7vw;
            margin-bottom: -3%;
        }

        ol {
            li {
                strong {
                    font-size: 1.3vw;
                }
            }
        }

        p {
            font-size: 1.3vw;
            margin-bottom: 1%;
        }
    }
}

$xs: 320px;
$sm: 480px;
$md: 767px;
$lg: 1024px;
$xl: 1920px;

@media (min-width: $xs) and (max-width: $sm) and (orientation: portrait) {

    h1 {
        font-size: 30px;
    }

    .blog {
        margin-top: 20%;

        .blog-body {
            flex-direction: column;

            .blog-body-content {
                flex-direction: column;

                .blog-card {
                    width: 95% !important;

                    h3 {
                        font-size: 16px;
                    }

                    .blog-card-text {

                        span {
                            font-size: 3vw;
                        }

                        a {
                            font-size: 4vw;
                        }

                        .body {
                            p {
                                font-size: 2.8vw;
                            }
                        }

                        .Read {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .blog-body-text {
                width: 100%;
                padding-left: 0;

                .blog-body-categories {
                    h4 {
                        font-size: 4vw;
                    }

                    &:last-child {
                        h4 {
                            padding-top: 10%;
                        }
                    }

                    .latest {
                        padding: 3% 0;

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .bcat {
        font-size: 3vw;
        padding: 3% 0;
    }

    .blog-details {
        margin-top: 20%;

        img {
            width: 100%;
        }

        .blog-card-text {
            .date {
                border-radius: 0 0 5px 5px;
                font-size: 3vw;
                color: var(--mid-golden);
            }

            h1 {
                font-size: 4vw;
            }

            p {
                font-size: 3vw;
                margin-bottom: 3%;

                strong {
                    font-size: 3.5vw;
                }
            }

            ul,
            ol {
                li {
                    font-size: 3vw;

                }
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: landscape) {

    h1 {
        font-size: 40px;
    }

    .blog {
        margin-top: 15%;

        .blog-body {

            .blog-body-content {
                flex-direction: column;

                .blog-card {
                    width: 100% !important;

                    .blog-card-text {
                        span {
                            border-radius: 0 0 5px 5px;
                            font-size: 2vw;
                        }

                        a {
                            font-size: 4vw;
                        }

                        .body {
                            p {
                                font-size: 2.8vw;
                            }
                        }

                        .Read {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .blog-body-text {
                width: 100%;
                padding-left: 0;

                .blog-body-categories {
                    h4 {
                        font-size: 4vw;
                    }

                    &:last-child {
                        h4 {
                            padding-top: 10%;
                        }
                    }

                    .latest {
                        padding: 3% 0;

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .bcat {
        font-size: 3vw;
        padding: 3% 0;
    }

    .blog-details {
        margin-top: 20%;

        img {
            width: 100%;
        }

        .blog-card-text {
            h1 {
                font-size: 4vw;
            }

            p {
                font-size: 3vw;
                margin-bottom: 3%;

                strong {
                    font-size: 3.5vw;
                }
            }

            ul,
            ol {
                li {
                    font-size: 3vw;
                    margin-bottom: 3%;
                }
            }
        }
    }

}

@media (min-width: 481px) and (max-width: $md) and (orientation: portrait) {

    h1 {
        font-size: 40px;
    }

    .blog {
        margin-top: 15%;

        .blog-body {
            .blog-body-content {
                flex-direction: column;

                .blog-card {
                    width: 100% !important;

                    .blog-card-text {
                        span {
                            border-radius: 0 0 5px 5px;
                            font-size: 2vw;
                        }

                        a {
                            font-size: 4vw;
                        }

                        .body {
                            p {
                                font-size: 2.8vw;
                            }
                        }

                        .Read {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .blog-body-text {
                width: 100%;
                padding-left: 0;

                .blog-body-categories {
                    h4 {
                        font-size: 4vw;
                    }

                    &:last-child {
                        h4 {
                            padding-top: 10%;
                        }
                    }

                    .latest {
                        padding: 3% 0;

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .bcat {
        font-size: 3vw;
        padding: 3% 0;
    }

    .blog-details {
        margin-top: 20%;

        img {
            width: 100%;
        }

        .blog-card-text {
            h1 {
                font-size: 4vw;
            }

            p {
                font-size: 3vw;
                margin-bottom: 3%;

                strong {
                    font-size: 3.5vw;
                }
            }

            ul,
            ol {
                li {
                    font-size: 3vw;
                    margin-bottom: 3%;
                }
            }
        }
    }


}

@media (min-width: 768px) and (max-width: $lg) {

    .blog {
        margin-top: 10%;

        .blog-body {

            .blog-body-content {
                flex-wrap: wrap;

                .blog-card {
                    width: calc(100% / 2.2) !important;

                    .blog-card-text {
                        span {
                            border-radius: 0 0 5px 5px;
                            font-size: 2vw;
                        }

                        a {
                            font-size: 4vw;
                        }

                        .body {
                            p {
                                font-size: 2.8vw;
                            }
                        }

                        .Read {
                            font-size: 3vw;
                        }
                    }
                }
            }

            .blog-body-text {
                width: 100%;
                padding-left: 0;

                .blog-body-categories {
                    h4 {
                        font-size: 4vw;
                    }

                    &:last-child {
                        h4 {
                            padding-top: 10%;
                        }
                    }

                    .latest {
                        padding: 3% 0;

                        a {
                            font-size: 3vw;
                        }
                    }
                }
            }
        }
    }

    .bcat {
        font-size: 3vw;
        padding: 3% 0;
    }

    .blog-details {
        margin-top: 20%;

        img {
            width: 100%;
        }

        .blog-card-text {
            h1 {
                font-size: 4vw;
            }

            p {
                font-size: 2.4vw;
                margin-bottom: 3%;

                strong {
                    font-size: 3vw;
                }
            }

            ul,
            ol {
                li {
                    font-size: 2.4vw;
                    margin-bottom: 3%;
                }
            }
        }
    }

}


@media (min-width: 1025px) and (max-width: $xl) {}