.event {
    .banner {
        margin-top: 5%;
        height: 90vh;
        padding: 5%;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
            font-size: 100px;
            width: 50%;
            text-align: center;
            text-transform: uppercase;
            color: #fff;
            font-weight: bold;
        }
    }
}