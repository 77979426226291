$black: #050606;
$black-rgb: rgb(5, 6, 6);
$white: #e9ebdf;
$white-rgb: rgb(233, 235, 223);
$light-golden: #eaefa2;
$light-golden-rgb: rgb(234, 239, 162);
$mid-golden: #d4c06c;
$mid-golden-rgb: rgb(212, 192, 108);
$golden: #b6861d;
$golden-rgb: rgb(182, 134, 29);
$light-brown: #b4843c;
$light-brown-rgb: rgb(180, 132, 60);
$brown: #9c5c04;
$brown-rgb: rgb(156, 92, 4);
$light-blue: #7b908f;
$light-blue-rgb: rgb(123, 144, 143);
$mid-blue: #5c6c74;
$mid-blue-rgb: rgb(92, 108, 116);
$dark-blue: #5c7471;
$dark-blue-rgb: rgb(92, 116, 113);
$heading: 'Dancing Script', cursive;
$gradient: linear-gradient(315deg, #fffcf9 0%, #ffd166 74%);
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
$font1: "Playfair Display", serif;

// Guitar 
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #fff;
}

p {
    color: grey;
}

.classes-page {
    margin-top: 10%;

    h1 {
        color: $mid-golden;
        margin-bottom: 5%;
    }
}

.drums-heading {
    padding: 0 5% 5% 5%;
    display: flex;
    align-items: stretch;

    img {
        width: 40%;
        border: none;
        border-radius: 20px;
        // height: 40vh;
        object-fit: cover;
    }

    .drums-heading-text {
        width: 70%;
        margin-left: 5%;

        p {
            font-size: 18px;
            line-height: 180%;
        }
    }

}

.guitar-what {
    h2 {
        text-align: center;
        margin-bottom: 3%;
    }

    .guitar-what-body {
        display: flex;
        padding: 0 5% 5% 5%;

        .guitar-what-card {
            width: calc(100% / 3);
            margin: 10px;
            padding: 20px 40px;
            border: 1px solid rgba($color: #fff, $alpha: 0.2);
            border-radius: 10px;

            svg {
                width: 70px;
                height: 70px;
                fill: $golden;
                margin-bottom: 20px;
            }

            p {
                color: grey;
            }

            h4 {
                font-family: $font1;
            }
        }
    }
}

.guitar-instructor {
    padding: 0 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .bg {
        position: absolute;
        top: 15%;
        left: 40%;
        // transform: scaleX(-1);
        rotate: -35deg;
        width: 35%;
    }

    img {
        border: none;
        width: 30%;
        margin: 0 5%;
    }

    h4 {
        font-size: 30px;
        font-family: $font1;
        margin-bottom: 20px;
    }

    .instructor-body {
        width: 70%;

        .instructor-about {
            p {
                font-size: 18px;
                font-style: italic;
            }
        }

        .instructor-card {
            margin-top: 5%;

            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 5%;

                li {
                    width: calc(100% / 3);
                    margin-right: 10px;
                    color: #fff;
                    display: flex;
                    align-items: baseline;
                    font-size: 18px;

                    span {
                        font-size: 100px;
                        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
                        color: $golden;
                        margin-right: 10px;
                        margin-top: -20px;
                    }

                }

            }
        }
    }

}

.guitar-banner {
    margin: 5%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    padding: 10%;
    height: 70vh;
    border-radius: 10px;
    background-image: url("../Images/Classes/Guitar/guitar\ -\ banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 20%;

    p {
        font-size: 20px;
    }

    a {
        text-decoration: none;
        text-align: center;
        padding: 10px 20px;
        border-radius: 10px;
        background: #fff;
        color: #000;
        text-transform: capitalize;
        font-size: 20px;
        width: 150px;
        display: block;
        margin-top: 20px;
        margin-left: auto;
    }

    p,
    h3 {
        color: #000;
        width: 60%;
        margin-left: auto;
        text-align: right;
    }

    h3 {
        font-size: 40px;
        font-weight: bold;
        font-family: $font1;
        margin-bottom: 30px;
    }
}

.guitar {
    .guitar-instructor {
        img {
            width: 20% !important;
        }
    }
}

.keyboard {
    .guitar-banner {
        background-image: url("../Images/Classes/Keyboard/keyboard\ -\ banner.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 20%;

        a {
            margin-right: auto;
            margin-left: 0;
        }

        p,
        h3 {
            margin-right: auto;
            text-align: left;
            margin-left: 0;
        }
    }

    .guitar-instructor {
        img {
            width: 30%;
        }
    }
}

.drums {
    .guitar-banner {
        background-image: url("../Images/Classes/Drums/16600.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 30%;
    }

    .guitar-instructor {
        img {
            width: 40%;
        }
    }
}

.vocal {
    .guitar-banner {
        background-image: url("../Images/Classes/Vocal/vocal\ banner.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 10% 10%;
    }

    .guitar-instructor {
        img {
            width: 15%;
        }
    }
}

.dance {
    .guitar-banner {
        background-image: url("../Images/Classes/Dance/dance\ -\ banner.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 20%;

    }
}

@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {

    .guitar,
    .drums,
    .vocal,
    .keyboard,
    .dance {
        margin-top: 30%;
    }

    .drums-heading {
        flex-direction: column;

        img {
            width: 100%;
        }

        .drums-heading-text {
            width: 100%;
            margin-left: 0;


            p {
                font-size: 16px;
                margin-top: 5%;

            }
        }

    }

    .guitar-what {
        .guitar-what-body {
            flex-direction: column;

            .guitar-what-card {
                width: 95%;
            }
        }
    }

    .guitar-instructor {
        flex-direction: column;

        img {
            display: none;
        }

        .instructor-body {
            width: 100%;

            .instructor-card {
                ul {
                    li {
                        font-size: 16px;

                        span {
                            font-size: 60px;
                        }
                    }
                }
            }

            .instructor-about {
                p {
                    font-size: 16px;
                }
            }
        }
    }

    .guitar-banner {
        height: 45vh;
        background-position: 20% 0%;
        padding: 5%;

        h3 {
            font-size: 20px;
            margin: 20px 0 20px auto;
        }

        p {
            font-size: 16px;
        }

        a {
            width: 150px;
            padding: 10px;
            font-size: 18px;
        }
    }

    .vocal{
        .guitar-banner{
            background-position: 50% 10%;
        }
    }

    .keyboard{
        .guitar-banner{
            background-position: 50% 10%;
        }
    }

    .dance{
        .guitar-banner{
            background-position: 20% 10%;
        }
    }
}

@media (min-width: 481px) and (max-width: 767px) and (orientation: portrait) {

    .guitar,
    .drums,
    .vocal,
    .keyboard,
    .dance {
        margin-top: 20%;
    }

    .drums-heading {
        flex-direction: column;

        img {
            width: 100%;
        }

        .drums-heading-text {
            width: 100%;
            margin-left: 0;


            p {
                font-size: 16px;
                margin-top: 5%;
            }
        }

    }

    .guitar-what {
        .guitar-what-body {
            flex-direction: column;

            .guitar-what-card {
                width: 95%;
            }
        }
    }

    .guitar-instructor {
        flex-direction: column;

        img {
            display: none;
        }

        .instructor-body {
            width: 100%;

            .instructor-card {
                ul {
                    li {
                        font-size: 16px;

                        span {
                            font-size: 60px;
                        }
                    }
                }
            }

            .instructor-about {
                p {
                    font-size: 16px;
                }
            }
        }
    }

    .guitar-banner {
        height: 45vh;
        background-position: 20% 0%;
        padding: 5%;

        h3 {
            font-size: 25px;
            margin: 20px 0 20px auto;
        }

        p {
            font-size: 18px;
        }

        a {
            width: 100px;
            padding: 10px;
            font-size: 18px;
        }
    }
}

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {

    .guitar,
    .drums,
    .vocal,
    .keyboard,
    .dance {
        margin-top: 20%;
    }

    .drums-heading {
        flex-direction: column;

        img {
            width: 100%;
        }

        .drums-heading-text {
            width: 100%;
            margin-left: 0;


            p {
                font-size: 16px;
                margin-top: 5%;

            }
        }

    }

    .guitar-what {
        .guitar-what-body {
            flex-direction: column;

            .guitar-what-card {
                width: 95%;
            }
        }
    }

    .guitar-instructor {
        flex-direction: column;

        img {
            display: none;
        }

        .instructor-body {
            width: 100%;

            .instructor-card {
                ul {
                    li {
                        font-size: 16px;

                        span {
                            font-size: 60px;
                        }
                    }
                }
            }

            .instructor-about {
                p {
                    font-size: 16px;
                }
            }
        }
    }

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .guitar,
    .drums,
    .vocal,
    .keyboard,
    .dance {
        margin-top: 20%;
    }


}