/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .header-slider .carousel-item img {
    height: 100vh;
  }

  .privacy h2 {
    color: var(--mid-golden);
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {

  .privacy h2 {
    color: var(--mid-golden);
  }

  /* navbar */
  .header-logo img {
    width: 160px;
  }

  /* navbar ends */

  /* slider */
  .header-form input {
    width: 50%;
  }

  .header-form {
    top: 15%;
  }

  /* slider ends */

  /* achievements */
  .achievements-content-img {
    padding: 0 5%;
  }

  .achievements-content-img img {
    width: 30%;
  }

  /* achievements ends */

  /* footer */


  /* footer ends */

}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .dropdown-menu {
    top: 100% !important;
  }

  .header-navbar li a {
    text-align: left;
  }

  .header-navbar li a:hover {
    background-color: transparent;
  }

  .privacy {
    margin-top: 15%;
  }

  .privacy h2 {
    color: var(--mid-golden);
  }

  /* common */
  section h1::before,
  section h1::after {
    width: 10px;
  }

  section h1 {
    font-size: 1.2em;
  }

  section {
    padding: .5em;
  }

  /* common ends */

  /* navbar */

  .navbar-nav li {
    margin-left: auto;
  }

  .header-logo img {
    width: 150px;
  }

  /* navbar ends */

  /* slider */

  .header-form div textarea,
  .form-toggle div textarea {
    flex-grow: 1;
    height: 20vh;
  }

  .header-slider {
    margin-top: 5%;
  }

  .header-slider .header-carousel-caption {
    width: 50%;
    margin: 0;
  }

  .header-slider .header-carousel-caption h5 {
    font-size: 3vw;
    margin-bottom: 2%;
  }

  .header-slider .header-carousel-caption {
    top: 35%;
  }

  .header-slider .header-carousel-caption a {
    font-size: 2vw;
    padding: 2% 5%;
  }

  .header-form input {
    width: 50%;
  }

  .header-form {
    top: 15%;
  }

  .carousel-item {
    margin-top: 8%;
  }

  /* slider ends */

  /* about  */

  .about {
    flex-wrap: nowrap;
  }

  .about div {
    width: 100%;
  }

  .about .about-text h6 {
    font-size: 1.1em;
    margin: 5%;
    text-align: justify;
  }


  /* about ends */

  /* classes */
  /* .classes .classes-body {
    width: calc(100% / 2.2)
  }

  .classes h4 {
    font-size: 3vw;
  }

  .classes .classes-text h3 {
    font-size: 2.3vw;
  }

  .classes .classes-text p {
    font-size: 1.5vw;
  } */

  /* classes ends */

  /* affiliations */
  .affiliations-content {
    flex-wrap: wrap;
  }

  .affiliations-content div img {
    margin-top: 10%;
  }


  .affiliations .cer-body .cer-body-img img {
    max-width: 100%;
  }

  .affiliations .cer-body .cer-body-img img {
    height: 120px;
    width: 200px;
    display: block;
    text-align: center;
  }

  .affiliations .image {
    height: 100px;
    width: 200px;
  }

  /* affiliations ends */

  /* team */

  .name {
    font-size: 1em;
  }

  .position {
    font-size: .9em;
  }

  /* team ends */

  /* testimonials */

  figure.snip1157 blockquote {
    font-size: 1em;
  }

  figure.snip1157 .author h5 {
    font-size: 1.5vw;
  }

  /* testimonials ends */

  /* achievements */
  .achievements-content-img {
flex-wrap: wrap;
    padding: 0 5%;
  }

  .achievements-content-img img {
    width: 30%;
  }

  /* achievements ends */

  /* gallery */
  .gallery img {
    width: 50%;
  }

  /* gallery ends */

  /* footer */
  .footer-widget ul li a {
    font-size: 2vw;
  }

  /* footer ends */

}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .dropdown-menu {
    top: 100% !important;
  }

  .header-navbar li a {
    text-align: left;
  }

  .header-navbar li a:hover {
    background-color: transparent;
  }

  .avatar>img {
    width: 150px;
  }

  .teamcol {
    margin-top: 20% !important;
  }

  .member-name {
    margin-top: 40%;
  }

  .privacy {
    margin-top: 15%;
  }

  .privacy h2 {
    color: var(--mid-golden);
  }

  /* common */
  section h1::before,
  section h1::after {
    width: 10px;
  }

  section h1 {
    font-size: 4vw;
  }

  section {
    padding: .5em;
  }

  /* common ends */

  /* navbar */

  .navbar-nav li {
    margin-left: auto;
  }

  .header-logo img {
    width: 150px;
  }

  /* navbar ends */

  /* slider */

  .header-form div textarea,
  .form-toggle div textarea {
    flex-grow: 1;
    height: 20vh;
  }

  .header-slider {
    margin-top: 5%;
  }

  .header-slider .header-carousel-caption {
    width: 50%;
    margin: 0;
  }

  .header-slider .header-carousel-caption h5 {
    font-size: 3vw;
    margin-bottom: 2%;
  }

  .header-slider .header-carousel-caption {
    top: 35%;
  }

  .header-slider .header-carousel-caption a {
    font-size: 2vw;
    padding: 2% 5%;
  }

  .header-form input {
    width: 50%;
  }

  .header-form {
    top: 15%;
  }

  .carousel-item {
    margin-top: 8%;
  }

  /* slider ends */

  /* about  */

  .about {
    flex-wrap: nowrap;
  }

  .about div {
    width: 100%;
  }

  .about .about-text h6 {
    font-size: 1.1em;
    margin: 5%;
    text-align: justify;
  }


  /* about ends */

  /* classes */
  /* .classes .classes-body {
    width: calc(100% / 2.2)
  }

  .classes h4 {
    font-size: 3vw;
  }

  .classes .classes-text h3 {
    font-size: 2.3vw;
  }

  .classes .classes-text p {
    font-size: 1.5vw;
  } */

  /* classes ends */

  /* affiliations */
  .affiliations-content {
    flex-wrap: wrap;
  }

  .affiliations-content div img {
    margin-top: 10%;
  }

  .affiliations .cer-body .cer-body-img img {
    max-width: 100%;
  }

  .affiliations .cer-body .cer-body-img img {
    height: 120px;
    width: 200px;
    display: block;
    text-align: center;
  }

  .affiliations .image {
    height: 100px;
    width: 200px;
  }

  /* affiliations ends */

  /* team */

  .name {
    font-size: 1em;
  }

  .position {
    font-size: .9em;
  }

  /* team ends */

  /* testimonials */

  figure.snip1157 blockquote {
    font-size: 1em;
  }

  figure.snip1157 .author h5 {
    font-size: 1.5vw;
  }

  /* testimonials ends */

  /* achievements */
  .achievements-content-img {

    padding: 0 5%;
  }

  .achievements-content-img img {
    width: 30%;
  }

  /* achievements ends */

  /* gallery */
  .gallery img {
    width: 50%;
  }

  /* gallery ends */

  /* footer */
  .footer-widget ul li a {
    font-size: 16px;
  }

  /* footer ends */
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {

  .popup {
    width: 100%;
  }


  .header-navbar li a {
    text-align: left;
  }

  .dropdown-menu {
    top: 100% !important;
  }

  .header-navbar li a:hover {
    background-color: transparent;
  }

  .teamGrid {
    grid-template-columns: 100%;
    margin-top: 0;
    position: relative;

  }

 

  .teamcol {
    margin-top: 20% !important;
  }


  .member-name {
    margin-top: 30%;
  }


  .privacy {
    margin-top: 20%;
  }

  .privacy h1 {
    font-size: 30px;
  }

  .privacy h4 {
    font-size: 18px;
  }

  .privacy p {
    font-size: 14px;
  }

  .privacy h2 {
    font-size: 16px;
    color: var(--mid-golden);
  }

  .privacy h3 {
    font-size: 14px;
    font-weight: bold;
  }

  .pages-about {
    margin-top: 35%;
  }

  .pages-about h1 {
    font-size: 30px;
  }

  .pages-about-founder h2 {
    font-size: 30px;
  }

  .pages-about-founder h6 {
    font-size: 20px;
  }

  .pages-about-founder img {
    box-shadow: -10px -10px 4px var(--mid-golden);
    width: 80%;
    margin: 5% auto;
    float: none;
  }

  .pages-about-founder {
    margin: 3em 0;
  }

  .pages-about p {
    font-size: 14px;
  }

  .pages-about-founder button {
    font-size: 14px;
  }

  .pages-team {
    margin-top: 7em;
  }

  .pages-team-img::before,
  .pages-team-img::after {
    display: none;
  }

  .pages-team-body {
    flex-direction: column;
  }

  .pages-team-img {
    width: 100%;
  }

  .pages-team-text {
    width: 100%;
    margin-top: 5%;
  }

  .pages-team-text h2 {
    font-size: 2em;
  }

  .pages-team-text h6::after {
    left: 40%;
  }

  .pages-team-text p {
    font-size: 14px;
  }

  .pages-team-body:nth-child(odd) {
    flex-direction: column;
  }

  .pages-team-body:nth-child(odd) .pages-team-text {
    padding: 0 5%;
  }

  .pages-team-img img {
    width: 80%;
  }

  .pages-team h1 {
    font-size: 10vw;
  }

  /* common */
  section h1::before,
  section h1::after {
    width: 10px;
  }

  section h1 {
    font-size: 1.2em;
  }

  section {
    padding: .5em;
  }

  /* common ends */

  /* navbar */
  .header-logo img {
    width: 120px;
  }

  .navbar-nav li {
    margin-left: auto;
  }



  /* navbar ends */

  /* slider */



  .header-slider {
    margin-top: 10%;
  }

  .header-slider .header-carousel-caption {
    width: 100%;
    margin: 0;
  }

  .header-slider .header-carousel-caption h5 {
    font-size: 4vw;
    margin-bottom: 5%;
  }

  .header-slider .header-carousel-caption {
    top: 15%;
  }

  .header-slider .header-carousel-caption a {
    font-size: 3vw;
    padding: 2% 5%;
  }

  .form-toggle div {
    flex-wrap: nowrap;
  }

  .form-toggle input {
    width: 50%;
  }

  .carousel-item {
    margin-top: 15%;
  }

  .header-form {
    display: none;
  }

  .form-toggle {
    display: block;
  }

  /* slider ends */

  /* about  */
  .about div {
    width: 100%;
  }

  .about .about-text h6 {
    font-size: .8em;
    margin: 5%;
    text-align: justify;
  }

  /* about ends */

  /* classes */
  .classes .classes-body {
    width: 100%;
  }

  .classes h4 {
    font-size: 6vw;
  }

  .classes .classes-text h3 {
    font-size: 3.7vw;
  }

  .classes .classes-text p {
    font-size: 3vw;
  }

  /* classes ends */

  /* affiliations */
  .affiliations-content {
    flex-wrap: wrap;
  }

  .affiliations-content div img {
    margin-top: 10%;
  }

  #Frames {
    width: 100%;
  }

  .affiliations .cer-body .cer-body-img img {
    max-width: 100%;
  }

  .affiliations .cer-body .cer-body-img img {
    height: 150px;
    width: 250px;
    display: block;
    text-align: center;
  }

  .affiliations .image {
    height: 200px;
    width: 300px;
  }

  /* affiliations ends */

  /* team */

  .name {
    font-size: 1em;
  }

  .position {
    font-size: .9em;
  }

  /* team ends */

  /* testimonials */

  figure.snip1157 blockquote {
    font-size: 1em;
  }


  figure.snip1157 .author h5 {
    font-size: 3vw;
  }

  /* testimonials ends */

  /* achievements */
  .achievements-content-img {
    flex-wrap: wrap;
    justify-content: center;
  }

  .achievements-content-img img {
    width: 40%;
    margin: 2%;
  }

  /* achievements ends */

  /* gallery */
  .gallery img {
    width: 50%;
  }

  /* gallery ends */

  /* footer */
  .footer-widget ul li a {
    font-size: 3vw;
  }

  /* footer ends */


}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Portait)
  ##Screen = B/w 481px to 767px
*/


@media (min-width: 481px) and (max-width: 767px) and (orientation: portrait) {

  .popup {
    width: 100%;
  }


  .header-navbar li a {
    text-align: left;
  }

  .dropdown-menu {
    top: 100% !important;
  }

  .header-navbar li a:hover {
    background-color: transparent;
  }

  .teamGrid {
    grid-template-columns: 100%;
    margin-top: 0;
  }

  .teamcol {
    margin-top: 30% !important;
  }


  .member-name {
    margin-top: 40%;
  }


  .privacy {
    margin-top: 20%;
  }

  .privacy h1 {
    font-size: 30px;
  }

  .privacy h4 {
    font-size: 18px;
  }

  .privacy p {
    font-size: 14px;
  }

  .privacy h2 {
    font-size: 16px;
    color: var(--mid-golden);
  }

  .privacy h3 {
    font-size: 14px;
    font-weight: bold;
  }

  .pages-about {
    margin-top: 35%;
  }

  .pages-about h1 {
    font-size: 30px;
  }

  .pages-about-founder h2 {
    font-size: 30px;
  }

  .pages-about-founder h6 {
    font-size: 20px;
  }

  .pages-about-founder img {
    box-shadow: -10px -10px 4px var(--mid-golden);
    width: 80%;
    margin: 5% auto;
    float: none;
  }

  .pages-about-founder {
    margin: 3em 0;
  }

  .pages-about p {
    font-size: 14px;
  }

  .pages-about-founder button {
    font-size: 14px;
  }

  .pages-team {
    margin-top: 7em;
  }

  .pages-team-img::before,
  .pages-team-img::after {
    display: none;
  }

  .pages-team-body {
    flex-direction: column;
  }

  .pages-team-img {
    width: 100%;
  }

  .pages-team-text {
    width: 100%;
    margin-top: 5%;
  }

  .pages-team-text h2 {
    font-size: 2em;
  }

  .pages-team-text h6::after {
    left: 40%;
  }

  .pages-team-text p {
    font-size: 14px;
  }

  .pages-team-body:nth-child(odd) {
    flex-direction: column;
  }

  .pages-team-body:nth-child(odd) .pages-team-text {
    padding: 0 5%;
  }

  .pages-team-img img {
    width: 80%;
  }

  .pages-team h1 {
    font-size: 10vw;
  }

  /* common */
  section h1::before,
  section h1::after {
    width: 10px;
  }

  section h1 {
    font-size: 1.2em;
  }

  section {
    padding: .5em;
  }

  /* common ends */

  /* navbar */
  .header-logo img {
    width: 120px;
  }

  .navbar-nav li {
    margin-left: auto;
  }



  /* navbar ends */

  /* slider */


  .header-slider {
    margin-top: 10%;
  }

  .header-slider .header-carousel-caption {
    width: 100%;
    margin: 0;
  }

  .header-slider .header-carousel-caption h5 {
    font-size: 4vw;
    margin-bottom: 5%;
  }

  .header-slider .header-carousel-caption {
    top: 15%;
  }

  .header-slider .header-carousel-caption a {
    font-size: 3vw;
    padding: 2% 5%;
  }

  .form-toggle div {
    flex-wrap: nowrap;
  }

  .form-toggle input {
    width: 50%;
  }

  .carousel-item {
    margin-top: 15%;
  }

  .header-form {
    display: none;
  }

  .form-toggle {
    display: block;
  }

  /* slider ends */

  /* about  */
  .about div {
    width: 100%;
  }

  .about .about-text h6 {
    font-size: .8em;
    margin: 5%;
    text-align: justify;
  }

  /* about ends */

  /* classes */
  .classes .classes-body {
    width: 100%;
  }

  .classes h4 {
    font-size: 6vw;
  }

  .classes .classes-text h3 {
    font-size: 3.7vw;
  }

  .classes .classes-text p {
    font-size: 3vw;
  }

  /* classes ends */

  /* affiliations */
  .affiliations-content {
    flex-wrap: wrap;
  }

  .affiliations-content div img {
    margin-top: 10%;
  }

  #Frames {
    width: 100%;
  }

  .affiliations .cer-body .cer-body-img img {
    max-width: 100%;
  }

  .affiliations .cer-body .cer-body-img img {
    height: 150px;
    width: 250px;
    display: block;
    text-align: center;
  }

  .affiliations .image {
    height: 200px;
    width: 300px;
  }


  /* affiliations ends */

  /* team */

  .name {
    font-size: 1em;
  }

  .position {
    font-size: .9em;
  }

  /* team ends */

  /* testimonials */

  figure.snip1157 blockquote {
    font-size: 1em;
  }

  figure.snip1157 .author h5 {
    font-size: 3vw;
  }

  /* testimonials ends */

  /* achievements */
  .achievements-content-img {
    flex-wrap: wrap;
    justify-content: center;
  }

  .achievements-content-img img {
    width: 80%;
    margin: 5% 0;
  }

  /* achievements ends */

  /* gallery */
  .gallery img {
    width: 50%;
  }

  /* gallery ends */

  /* footer */
  .footer-widget ul li a {
    font-size: 3vw;
  }

  /* footer ends */

}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {

  .popup {
    width: 100%;
  }

  .popup img {
    width: 100%;
  }

  .dropdown-menu {
    top: 100% !important;
  }

  /* #achievements .after {
    top: -2%;
    right: 0;
    width: 100%;
    height: 50px;
    border: 1px solid #fff;
  } */

  .header-navbar li a {
    text-align: left;
  }

  .header-navbar li a:hover {
    background-color: transparent;
  }

  .navbar-nav .dropdown-menu {
    margin-left: -30%;
  }

  .teamGrid {
    grid-template-columns: 100%;
    margin-top: 0;
  }

  .teamcol {
    margin-top: 30% !important;
  }


  .member-name {
    margin-top: 40%;
  }


  .privacy {
    margin-top: 30%;
  }

  .privacy h1 {
    font-size: 30px;
  }

  .privacy h4 {
    font-size: 18px;
  }

  .privacy p {
    font-size: 14px;
  }

  .privacy h2 {
    font-size: 16px;
    color: var(--mid-golden);
  }

  .privacy h3 {
    font-size: 14px;
    font-weight: bold;
  }

  .pages-about-founder h2,
  .pages-about-founder2 h2 {
    font-size: 30px;
  }

  .pages-about-founder h6,
  .pages-about-founder2 h6 {
    font-size: 20px;
  }

  .pages-about-founder img,
  .pages-about-founder2 img {
    box-shadow: -10px -10px 4px var(--mid-golden);
    width: 80%;
    margin: 5% auto;
    float: none!important;
  }

  .pages-about-founder,
  .pages-about-founder2 {
    margin: 3em 0;
  }

  .pages-about p {
    font-size: 14px;
  }

  .pages-team {
    margin-top: 7em;
  }

  .pages-team-img::before,
  .pages-team-img::after {
    display: none;
  }

  .pages-team-body {
    flex-direction: column;
  }

  .pages-team-img {
    width: 100%;
  }

  .pages-team-text {
    width: 100%;
    margin-top: 5%;
  }

  .pages-team-text h2 {
    font-size: 2em;
  }

  .pages-team-text h6::after {
    left: 40%;
  }

  .pages-team-text p {
    font-size: 14px;
  }

  .pages-team-body:nth-child(odd) {
    flex-direction: column;
  }

  .pages-team-body:nth-child(odd) .pages-team-text {
    padding: 0 5%;
  }

  .pages-team-img img {
    width: 80%;
  }

  .pages-team h1 {
    font-size: 10vw;
  }

  /* common */
  section h1::before,
  section h1::after {
    width: 10px;
  }

  section h1 {
    font-size: 1.2em;
  }

  section {
    padding: .5em;
  }

  /* common ends */

  /* navbar */
  .header-logo img {
    width: 100px;
  }

  .navbar-nav li {
    margin-left: auto;
  }

  /* navbar ends */

  /* slider */

  .header-slider {
    margin-top: 15%;
  }

  .header-slider .header-carousel-caption {
    width: 100%;
    margin: 0;
  }

  .header-slider .header-carousel-caption h5 {
    font-size: 3.5vw;
    margin-bottom: 2%;
  }

  .header-slider .header-carousel-caption {
    top: 15%;
  }

  .header-slider .header-carousel-caption a {
    font-size: 2.5vw;
    padding: 2% 5%;
  }

  .form-toggle div {
    flex-wrap: nowrap;
  }

  .form-toggle input {
    width: 50%;
  }

  .carousel-item {
    margin-top: 15%;
  }

  .header-form {
    display: none;
  }

  .form-toggle {
    display: block;
  }

  /* slider ends */

  /* about  */
  .about div {
    width: 100%;
    margin-top: 5%;
  }

  .about .about-text h6 {
    font-size: .8em;
    margin: 5% 0;
    text-align: justify;
  }

  .pages-about-founder button {
    font-size: 12px;
    border-radius: 5px;

  }

  /* about ends */

  /* classes */
  .classes .classes-body {
    width: 100%;
  }

  .classes h4 {
    font-size: 6vw;
  }

  .classes .classes-text h3 {
    font-size: 3.7vw;
  }

  .classes .classes-text p {
    font-size: 3vw;
  }

  /* classes ends */

  /* affiliations */
  
  .affiliations-content {
    flex-wrap: wrap;
  }

  .affiliations-content div img {
    margin-top: 10%;
  }

  #Frames {
    width: 100%;
  }

  .affiliations .cer-body .cer-body-img img {
    max-width: 100%;
  }

  .affiliations .cer-body .cer-body-img img {
    height: 150px;
    width: 250px;
    display: block;
    text-align: center;
  }

  .affiliations .image {
    height: 200px;
    width: 300px;
  }

  /* affiliations ends */

  /* team */
  .team-img {
    width: 80%;
  }

  .name {
    font-size: 1em;
  }

  .position {
    font-size: .9em;
  }

  /* team ends */

  /* testimonials */

  figure.snip1157 blockquote {
    font-size: 1em;
  }

  figure.snip1157 .author h5 {
    font-size: 3.5vw;
  }

  /* testimonials ends */

  /* achievements */
  .achievements-content-img {
    flex-wrap: wrap;
    justify-content: center;
  }

  .achievements-content-img img {
    width: 80%;
    margin: 5% 0;
    height: 100%;
  }

  /* achievements ends */

  /* gallery */
  .gallery
  {
    overflow: hidden;

  }
  .gallery img {
    width: 50%;
  }

  .gallery-filter button {
    font-size: .9em;
    padding: 0.2em 2%;
  }

  .pages-about h1,
  .pages-gallery h1,
  .pages-team h1 {
    margin: 5em 0 1em 0;
    font-size: 6vw;
  }

  /* gallery ends */

  /* footer */
  .footer-widget ul li a {
    font-size: 3vw;
  }

  /* footer ends */

}